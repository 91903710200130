/* ======================================================================
 layout
====================================================================== */

/* =================================
 l_container
================================= */
.l_container {
}


/* =================================
 l_header
================================= */
.l_header {
	margin: 0 auto;
  position: fixed;
  z-index: 2;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 40px 0;
  mix-blend-mode:difference;
  .inner {
  }
  .left {
    .logo {
      display: block;
      .img {
        
      }
    }
  }
  .right {
    .list {
      display: flex;
      align-items: center;
      .item{
       .link {
          color: #fff;
       }
       & + .item {
        margin-left: 2.93vw;
       }
       &.current {
         .link {
          position: relative;
          &::after {
            position: absolute;
            bottom: -6px;
            left: 0;
            content: '';
            width: 100%;
            height: 1px;
            background: #fff;
            transform: scale(1);
          }

         }
        
       }
      }
    }
  }
  .hambarger_btn {
    display: none;
  }
  
    
  @include pc_tab {
  }
  
  
  @include sp { 
    margin: 35px 0;
    z-index: 10;
    .inner {
    }
    .left {
      .logo {
        .img {
        }
      }
    }
    .right {
      display: none;
    }
    .hambarger_btn {
      position: relative;
      display: block;
      border-radius: 200px;    
      cursor: pointer;
      /*width: 98px;*/
      width: 78px;
      height: 37px;
      border: 1px solid #fff;
      &.show {
        transition: opacity 0.4s;
        opacity: 1;
      }
      .open_btn {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        flex-direction: column;
        align-items: center;
        position: absolute;
        top: 0;
        width: 100%;
        height: 100%;
        .item + .item {
          margin: 9px 0 0 0;
        }
      }
      .close_btn {
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        .item {
          position: absolute;
          top: 17px;
          /*left: 29px;*/
          left: 19px;
          &:nth-child(1) {
            transform: rotate(25deg);        
          }
          &:nth-child(2) {
            transform: rotate(-25deg);        
          }
        }
      }
      .item {
        display: block;
        width: 38px;
        height: 1px;
        background-color: #fff;
      }
    }
  }
  &.open {
      .hambarger_btn {
        border: 1px solid #fff;
        &.show {
        }
        .open_btn {
          .item + .item {
          }
        }
        .close_btn {
          .item {
            &:nth-child(1) {
            }
            &:nth-child(2) {
            }
          }
        }
        .item {
        }
      }
  }
}


/* =================================
 sp_menu
================================= */
.sp_menu {
  display: none;
  
  @include sp {
    display: flex;
    flex-direction: column;
    position: fixed;
    z-index: 3;
    top: 0;
    bottom: 0;
    right: 0;
    height: 100%;
    padding: 34.67vw /*130px*/ 0 0;
    background-color: $color_1;
    width: 100%;
    overflow-y: scroll;
    overscroll-behavior-y: contain;
    .list {
      width: 100%;
      padding: 0 20px;
      .item {
        text-align: right;
        .link {
          color: #fff;          
        }
        & + .item {
          padding: 4vw/*15px*/ 0 0;            
        }
      }      
    }
    &.open {
      opacity: 1;
    }
  }
}


/* =================================
 l_main_visual
================================= */
.l_main_visual {
  background-color: #fff;
  padding-bottom: 9.08vw /*124px*/;
  .top {
    /*min-height: 390px;*/
    padding-top: 15.89vw;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    .txt_box {
      .ttl {
        flex-shrink: 0;
       .highlight {
       }       
       .light {
        display: block;      
       }
      }
    }
  }
  
  
  @include sp {
    overflow: hidden;
    .top {
      height: auto;
      min-height: unset;
      padding-bottom: 5.33vw /*20px;*/;
      padding-top:  41.07vw;
      .txt_box {
        .ttl {
         .highlight {
          font-size: 13.07vw/*49px*/;
          display: block;
         }
         .light {
          font-size: 10.13vw /*38px*/;
          margin-top: 2.93vw/*11px*/;
         }
        }
      }
    }
  }
}


/* =================================
 l_content
================================= */
.l_content {
  background-color: #fff;
}


/* =================================
 l_main
================================= */
.l_main {
}


/* =================================
 l_contact
================================= */
.l_contact {
  background-color: $color_1;
  color: #fff;
  padding: 7.32vw /*100px*/ 0  3.66vw /*50px*/;
  overflow: hidden;
  .inner {
  
  }
  .ttl {
  
  }
  .btn {
    
  }
  .move_txt_wrap {
    white-space:nowrap;
    margin: 3.66vw /*50px*/ 0 0;
    display: flex;
    .move_txt {
      padding-right: 1.61vw /*22px*/;
    }
    & + .move_txt_wrap {
      margin: 0.73vw /*10px*/ 0 0;
    }
    .phrase {
      & + .phrase {
        margin-left: 1.61vw /*22px*/;
      }
    }
    &.top_txt {
      .move_txt:nth-of-type(1) {
        animation: contact_loop 50s -25s linear infinite;
      }
      .move_txt:nth-of-type(2) {
        animation: contact_loop2 50s linear infinite;
      }              
    }
    &.bottom_txt {
      .move_txt:nth-of-type(1) {
        animation: contact_loop 80s -40s linear infinite;
      }
      .move_txt:nth-of-type(2) {
        animation: contact_loop2 80s linear infinite;
      }              
    }
  }
  @include sp { 
    padding: 13.33vw/*50px*/ 0 22.67vw/*85x*/;  
    .inner {
    }
    .ttl {
    }
    .btn {
      margin: 20vw /*75px*/ 0 0;
    }    
    .move_txt_wrap {
      margin: 20vw /*75px*/ 0 0;
      .move_txt {
        padding-right: 5.87vwvw /*22px*/;
      }
      & + .move_txt_wrap {
        margin: 2.67vw /*10px*/ 0 0;
      }
      
      
      .phrase {
        & + .phrase {
          margin-left: 5.87vw /*22px*/;
        }
      }
    }
  }


}

@keyframes contact_loop {
  0% {
    transform: translateX(100%);
  }
  to {
    transform: translateX(-100%);
  }
}
  
@keyframes contact_loop2 {
  0% {
    transform: translateX(0);
  }
  to {
    transform: translateX(-200%);
  }
}


/* =================================
 l_footer
================================= */
.l_footer {
  background-color: #fff;
  padding: 3.66vw/*50px*/ 0 2.2vw/*30px*/;
  .pagetop_box {
    display: flex;
    justify-content: flex-end;
    padding-right: 4.76vw /*65px*/;
  }
  .pagetop {
    width: 3.22vw /*44px*/;
    height: 12.15vw /*166px*/;
    color: $color_1;
    letter-spacing: .001em;
    writing-mode: vertical-lr;
    display: flex;
    align-items: center;
    cursor: pointer;
    .block{
      margin-bottom: 1.32vw/*18px*/;
      width: 1px;
      height: 6.37vw /*87px*/;
      display: block;
      background-image: linear-gradient(to bottom, $color_1 50%, transparent 50%);
      background-size: 100% 200%;
      animation: scroll-up 2.5s infinite  both;
    }
  }
  .inner {
  }
  .top {
    display: flex;
    .link {
    
    }
    .list {
      margin-left: 15.74vw/*215px*/;
      .item {
        .item_link {
          
        }
        & + .item {
          margin: 0.73vw/*10px*/ 0 0;
        }
      }
    }
  }
  .copyright {
    margin: 13.62vw /*186px*/ 0 0;
    .mark {
      margin: 0 0.5em 0 0; 
    }
    
  }

  @-webkit-keyframes scroll-up {
    0% {
      background-position: center 0
    }
    to {
      background-position: center 200%
    }
  }    

  
  @include sp {  
    padding: 10.67vw/*40px*/ 0;
    .pagetop_box {
      padding-right: 20px;
    }
    .pagetop {
      width: 11.73vw /*44px*/;
      height: 44.27vw /*166px*/;
      .block{
        margin-bottom: 4.8vw/*18px*/;
        height: 23.2vw /*87px*/;
      }
    }
    .inner {
    }
    .top {
      flex-direction: column;
      .link {
      }
      .list {
        margin-left: 0;
        margin-top: 8vw/*30px*/;
        .item {
          .item_link {

          }
          & + .item {
            margin: 2.67vw/*10px*/ 0 0;
          }
        }
      }
    }
    .copyright {
      margin: 26.67vw /*186px*/ 0 0;
      .mark {
        margin: 0 0.5em 0 0; 
      }

    }
  }
  
}



/* =================================
 stalker
================================= */
#stalker {
  display: none;
  pointer-events: none;
  position: fixed;
  top: -16px;
  left: -16px;
  width: 32px;
  height: 32px;
  /*background: rgba(253, 254, 0, 0.8);*/
  border-radius: 50%;
  transform: translate(-100px,-100px);
  transition: transform 0.2s;
  transition-timing-function: ease-out;
  z-index: 998;
  mix-blend-mode: difference;
  .btn {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border: 1px solid #fff;
    &::after {
      background-image: url("../img/common/icon_arrow_wh.svg");
    }
  }
}

#stalker.hov_{
  display: block;
  top: -40px;
  left: -40px;
  width: 80px;
  height: 80px;
  transition: .15s;
  /*background: rgba(253, 254, 0, 0.5);*/
}
