/* ======================================================================
 utility
====================================================================== */

/* =================================
 layout
================================= */
// clearfix
.u_cf {
  @include cf;
}

// pc・sp 出し分け（media-queryはサイトの仕様に合わせて変更すること）
@include pc_tab {
  .u_pc {
    display: block;
    &_i {
      display: inline;
    }
    &_ib {
      display: inline-block;
    }
    &_f {
      display: flex;
    }
    &_if {
      display: inline-flex;
    }
  }
  .u_sp {
    display: none;
    &_i,
    &_ib,
    &_f,
    &_if {
      display: none;
    }
  }
}

@include sp {
  .u_pc {
    display: none;
    &_i,
    &_ib,
    &_f,
    &_if {
      display: none;
    }
  }
  .u_sp {
    display: block;
    &_i {
      display: inline;
    }
    &_ib {
      display: inline-block;
    }
    &_f {
      display: flex;
    }
    &_if {
      display: inline-flex;
    }
  }
}

/* =================================
 content_size
================================= */
.u_content_size {
  padding: 0 2.93vw; /*40px*/
  @include pc_tab {
  }
  @include sp {
    width: 100%;  
    padding: 0 $sp_side_padding;   
  }
}

.u_content_2 {
  padding: 0 8.78vw;  /*120px*/
  @include pc_tab {
  }
  @include sp {
    width: 100%;  
    padding: 0 $sp_side_padding;
  }
}


.u_content_3 {
  padding: 0 5.86vw;  /*80px*/
  @include pc_tab {
  }
  @include sp {
    width: 100%;  
    padding: 0 $sp_side_padding;
  }
}







/* =================================
 text
================================= */
.u_txt_1 {
  font-size: 1.17vw /*16px*/;
  line-height: 1;
  @include pc_tab {
  }
  @include sp {
    font-size: 14px;
  }
}

.u_txt_2 {
  font-size: 9.22vw /*126px*/;
  line-height: 1;
  @include pc_tab {
  }
  @include sp {
  }
}

.u_txt_3 {
  font-size: 6.3vw /*86px*/;
  line-height: 1;
  @include pc_tab {
  }
  @include sp {
  }
}

.u_txt_4 {
  font-size: 14.64vw /*200px*/;
  line-height: 1;
  @include pc_tab {
  }
  @include sp {
    font-size: 21.87vw /*82px*/;
  }
}

.u_txt_5 {
  font-size: 25.62vw /*350px*/;
  line-height: 1;
  @include pc_tab {
  }
  @include sp {
    font-size: 24.53vw /*92px*/;    
  }
}

.u_txt_6 {
  font-size: 1.39vw /*19px*/;
  line-height: 1.4;
  @include pc_tab {
  }
  @include sp {
    font-size: 5.07vw/*19px*/;
  }
}

.u_txt_7 {
  font-size: 1.02vw /*14px*/;
  line-height: 2;
  @include pc_tab {
  }
  @include sp {
    font-size: 3.73vw/*14px*/;
  }
}

.u_txt_8 {
  font-size: 4.9vw /*67px*/;
  line-height: 1;
  @include pc_tab {
  }
  @include sp {
    font-size: 12vw /*45px*/;  
  }
}

.u_txt_9 {
  font-size: 13.91vw /*190px*/;
  line-height: 1;
  @include pc_tab {
  }
  @include sp {
    font-size: 13.87vw /*52px*/;
  }
}

.u_txt_10 {
  font-size: 8.42vw /*115px*/;
  line-height: 1;
  @include pc_tab {
  }
  
  @include sp {
    font-size: 13.87vw /*52px*/;
  }
}

.u_txt_11 {
  font-size: 2.05vw /*28px*/;
  line-height: 1;
  @include pc_tab {
  }
  @include sp {
    font-size: 7.47vw /*28px*/;
  }
}

.u_txt_12 {
  font-size: 1.02vw /*14px*/;
  line-height: 1;
  @include pc_tab {
  }
  @include sp {
    font-size: 3.73vw /*14px*/;
  }
}

.u_txt_13 {
  font-size: 1.61vw /*22px*/;
  line-height: 1;
  @include pc_tab {
  }
  @include sp {
    font-size: 5.87vw /*22px*/;
  }
}

.u_txt_14 {
  font-size: 9.87vw /*37px*/;
  line-height: 1;
  @include pc_tab {
  }
  @include sp {
  }
}

.u_txt_15 {
  font-size: 1.17vw /*16px*/;
  line-height: 1;
  @include pc_tab {
  }
  @include sp {
    font-size: 4.27vw /*16px*/;
  }
}

.u_txt_16 {
  font-size: 3vw /*41px*/;
  line-height: 1;
  @include pc_tab {
  }
  @include sp {
    /*font-size: 4.27vw 16px;*/
  }
}

.u_txt_17 {
  font-size: 3vw /*41px*/;
  line-height: 1;
  @include pc_tab {
  }
  @include sp {
    font-size: 10.93vw /*41px*/;
    line-height: 1.2;
  }
}

.u_txt_18 {
  font-size: 0.95vw /*13px*/;
  @include pc_tab {
  }
  @include sp {
    font-size: 3.47vw /*13px*/;
  }
}

.u_txt_19 {
  font-size: 1.02vw /*14px*/;
  line-height: 1.35;
  @include pc_tab {
  }
  @include sp {
    font-size: 3.73vw /*14px*/;
  }
}


.u_txt_20 {
  font-size: 4.39vw /*60px*/;
  line-height: 1;
  @include pc_tab {
  }
  @include sp {
    font-size: 5.33vw /*20px*/;
  }
}

.u_txt_21 {
  font-size: 1.46vw /*20px*/;
  line-height: 2;
  @include pc_tab {
  }
  @include sp {
    font-size: 5.33vw /*20px*/;
  }
}

.u_txt_22 {
  font-size: 1.46vw /*20px*/;
  line-height: 1;
  @include pc_tab {
  }
  @include sp {
    font-size: 5.33vw /*20px*/;
  }
}

.u_txt_23 {
  font-size: 0.88vw /*12px*/;
  line-height: 2;
  @include pc_tab {
  }
  @include sp {
    font-size: 3.2vw /*12px*/;
  }
}

.u_txt_24 {
  font-size: 0.81vw /*11px*/;
  line-height: 1.6;
  @include pc_tab {
  }
  @include sp {
    font-size: 2.93vw /*11px*/;
  }
}

.u_txt_25 {
  font-size: 1.32vw /*18px*/;
  line-height: 1;
  @include pc_tab {
  }
  @include sp {
    font-size: 4.8vw /*18px*/;
  }
}


/* =================================
 text weight
================================= */
.u_ex_light {
  font-weight: 200;
}

.u_light {
  font-weight: 300;
}

.u_egular {
  font-weight: 400;
}

.u_bold {
  font-weight: 700;
}

/* =================================
 text align
================================= */
.u_ta_c {
  text-align: center;
}

.u_ta_r {
  text-align: right;
}

.u_ta_cl {
  @include sp {
    text-align: center;
  }
}

.u_ta_lc {
  @include pc_tab {
    text-align: center;
  }
}

.u_ta_cr {
  @include sp {
    text-align: center;
  }
  @include pc_tab {
    text-align: right;
  }
}

.u_ta_rc {
  @include sp {
    text-align: right;
  }
  @include pc_tab {
    text-align: center;
  }
}

/* =================================
 paragraph margin
================================= */
.u_paragraph {
  & + & {
    margin: 15px 0 0;
  }
}

/* =================================
 hover animation
================================= */
.u_hover_1 {
  transition: opacity .3s;
  @include pc {
    &:hover {
      opacity: .7;
    }
  }
}

