/* ======================================================================
 component
====================================================================== */

/* =================================
 title
================================= */
.c_ttl_1 {
  margin: 0 0 64px;
  line-height: 1.6;
  text-align: center;
  font-weight: bold;
  letter-spacing: 0.03em;
  position: relative;
  padding-bottom: 20px;
  &::after {
    content: "";
    width: 48px;
    height: 4px;
    background: $color_2;
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
  }
  
  @include sp {
    margin: 0 0 40px;
    padding-bottom: 16px;

    .en {
      font-size: 12px;
      /*line-height: 1;*/
    }
    .sub {
      font-size: 14px;
      margin-top: 8px;    
    }
    .main {
      margin: 22px 0 0;
      font-size: 24px;

      .accent {
        .circle {
          &::before {
            width: 6px;
            height: 6px;
            top: -10px;
          }
        }
      }
    }
    &::after {
      width: 35px;
      height: 3px;
    }
  }
}

.c_ef_ttl {
  .c_ef_ttl_line_wrap {
    overflow: hidden;
    display: block;
    padding-bottom: 0.51vw;
  }
  .c_ef_ttl_line {
    transition: opacity .6s cubic-bezier(0.22, 1, 0.36, 1),transform 1.2s cubic-bezier(0.22, 1, 0.36, 1);
    transform-origin: left bottom;
    transform: translate3d(0,110%,0);
    will-change: auto;
    display: inline-block;    
  }
  .t {
    transition: opacity .6s cubic-bezier(0.22, 1, 0.36, 1),transform 1.2s cubic-bezier(0.22, 1, 0.36, 1);
    transform-origin: left bottom;
    transform: translate3d(0,110%,0);
    will-change: auto;
    display: inline-block;
    &:nth-of-type(1) {
      transition-delay: 0s;    
    }
    &:nth-of-type(2) {
      transition-delay: 0.03s;
    }
    &:nth-of-type(3) {
      transition-delay: 0.06s;  
    }
    &:nth-of-type(4) {
      transition-delay: 0.09s;      
    }
    &:nth-of-type(5) {
      transition-delay: 0.12s;      
    }
    &:nth-of-type(6) {
      transition-delay: 0.15s;      
    }
    &:nth-of-type(7) {
      transition-delay: 0.18s;      
    }
    &:nth-of-type(8) {
      transition-delay: 0.21s;      
    }
    &:nth-of-type(9) {
      transition-delay: 0.24s;      
    }
    &:nth-of-type(10) {
      transition-delay: 0.27s;      
    }
    &:nth-of-type(11) {
      transition-delay: 0.3s;      
    }
    &:nth-of-type(12) {
      transition-delay: 0.33s;      
    }
    &:nth-of-type(13) {
      transition-delay: 0.36s;      
    }
    &:nth-of-type(14) {
      transition-delay: 0.39s;      
    }
    &:nth-of-type(15) {
      transition-delay: 0.42s;      
    }
    &:nth-of-type(16) {
      transition-delay: 0.45s;      
    }
    &:nth-of-type(17) {
      transition-delay: 0.48s;      
    }
    &:nth-of-type(18) {
      transition-delay: 0.51s;      
    }
    &:nth-of-type(19) {
      transition-delay: 0.54s;      
    }
    &:nth-of-type(20) {
      transition-delay: 0.57s;      
    }
  }
  &._show {
    .c_ef_ttl_line {
      transform: translateZ(0);
      opacity: 1;
    }
    .t {
      transform: translateZ(0);
      opacity: 1;
    }
  
  
  }

}


/* =================================
 txt
================================= */
.c_ef_txt {
  .c_ef_txt_line_wrap {
    overflow: hidden;
    display: block;
    padding-bottom: 0.51vw;
  }
  .c_ef_txt_line {
    transition: opacity .6s cubic-bezier(0.22, 1, 0.36, 1),transform 1.2s cubic-bezier(0.22, 1, 0.36, 1);
    transform-origin: left bottom;
    transform: translate3d(0,110%,0);
    will-change: auto;
    display: inline-block;    

  }
  .t {
    transition: opacity .6s cubic-bezier(0.22, 1, 0.36, 1),transform 1.2s cubic-bezier(0.22, 1, 0.36, 1);
    transform-origin: left bottom;
    transform: translate3d(0,110%,0);
    will-change: auto;
    display: inline-block;
  }
  &._show {
    .c_ef_txt_line {
      transform: translateZ(0);
      opacity: 1;
    }
    .t {
      transform: translateZ(0);
      opacity: 1;
    }
  }
}

/* =================================
 buttons
================================= */
// c_gotop
.c_gotop {
}

.c_btn_link_1 {
  display: flex;
  align-items: center;
  .link {
    display: flex;
    align-items: center;    
  }
  .c_btn_txt_1 {
    margin-right: -5.86vw /*-80px*/;
    mix-blend-mode: difference;
    color: #fff;
    z-index: 1;
    
  }  
  &.c_btn_link_1--right {
    justify-content: flex-end;
  }
  &.c_btn_link_1--center {
    justify-content: center;
  }
  &.c_btn_link_1--white {
    .c_btn_txt_1 {
     color: #fff;
    }
    .c_btn_1 {
      border: 1px solid #fff;
      &::after {
        background-image: url("../img/common/icon_arrow_wh.svg");
      }
    }
  }
  
  @include pc_tab {
    .link {
      &:hover { 
        .c_btn_1 {
          transform: scale(1.2);
          background: $color_1;
          &::after {
            background-image: url("../img/common/icon_arrow_wh.svg");
          }
        }
      }

    }
    &.c_btn_link_1--white {
      .link:hover {
        .c_btn_1 {
          background-color: #fff;
          &::after {
            background-image: url("../img/common/icon_arrow.svg");
          }
        }
      }
    }
    
  }
  
  
  @include sp {
    .c_btn_txt_1 {
      margin-right: -12.27vw /*-46px*/;
      flex-shrink: 0;
      
    }  
    &.c_btn_link_1--white {
      .c_btn_txt_1 {
      }
      .c_btn_1 {
      }
    }    
  }
}


.c_btn_1,
.c_btn_1:link {
  display: flex;
  position: relative;
  align-items: center;
  justify-content: center;
  height: 22.18vw;
  width: 22.18vw /*303px*/;
  border-radius: 9999px;
  border: 1px solid $color_1;
  flex-shrink: 0;

	transition: all .4s cubic-bezier(.165,.84,.44,1);
	transition-timing-function: cubic-bezier(.165,.84,.44,1);
  
  
  
  &::after {
    content: "";
    display: block;
    position: absolute;
    width: 5.34vw;
    height: 5.34vw;
    background-image: url("../img/common/icon_arrow.svg");
    background-size: cover;
    transition: transform 0.3s;
    
  }
  
  &.c_btn_1--right {
    &::after {
      transform:rotate(-90deg);
    }  
  }
  
  @include pc_tab {
    &:hover {
      transform: scale(1.2);
      background: $color_1;
          
      &::after {
        background-image: url("../img/common/icon_arrow_wh.svg");
      }
      &:before {
      }
    }
  }
  @include sp {
    height: 55.2vw;
    width: 55.2vw /*207px*/;
    .img {
      width: 13.07vw /*49px*/;
      height: 13.07vw /*49px*/;
    }
    &::after {
      width: 13.33vw;
      height: 13.33vw;
    }
    
  }
}





.c_btn_2,
.c_btn_2:link {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 48px;
  width: 220px;
  border-radius: 9999px;
  font-size: 14px;
  font-weight: bold;
  line-height: 27px;
  color: #fff;
  transition: 0.3s;
  transform: rotateZ(0.03deg);
  letter-spacing: 0.03em;
  position: relative;
  padding: 0 16px 0 24px;
  background: $color_1;
  border: 3px solid $color_1;      
  
  &:before {
    position: absolute;
    content: "";
    display: block;
    width: 16px;
    height: 16px;
    background-size: cover;
    background-repeat: no-repeat;
    right: 22px;
    top: 50%;
    transform: translateY(-50%);
    z-index: 1;
    background-image: url("../img/p_top/arr_icon03.svg");
  }
  &:after {
    content: "";
    display: block;
    background-color: #FFF;
    width: 28px;
    height: 28px;
    border-radius: 9999px;
  }    
  &:visited,
  &:active {
    color: #fff;
  }
  @include pc_tab {
    &:hover {
      color: $color_1;
      background-color: #fff;
      &:before {
        background-image: url("../img/p_top/arr_icon06.svg");
      }
      &:after {
        background-color: $color_1;
      }    
    }
  }
  
  @include sp {
    &:before {
    }
    &:after {
    }    
    &:visited,
    &:active {
    }
  }
}



/* =================================
 img
================================= */
.c_ef_img {
  .c_ef_img_line {
    position: relative;
    display: block;
    overflow: hidden;
    display: block;
    &::before {
      position: absolute;
      top: -2px;
      left: 0;
      width: calc(100% + 1px);
      height: calc(100% + 4px);
      content: '';
      background-color: #fff;
      will-change: auto;
      transition: transform 1.2s cubic-bezier(0.22, 1, 0.36, 1);
      transition: transform 1.2s cubic-bezier(0.22, 1, 0.36, 1),-webkit-transform 1.2s cubic-bezier(0.22, 1, 0.36, 1);
      z-index: 1;
    }
  }
  .img {
  }
  &._show {
    .c_ef_img_line {
      &::before {
        transform: scaleX(0);
        transform-origin: right top;
      }
    }
  }

}

/* =================================
 table
================================= */
.c_table_1 {
}

.c_table_2 {
}

.c_table_3 {
}

/* =================================
 list
================================= */


/* =================================
 pager
================================= */


/* =================================
 link
================================= */
.c_link_1 {
  position: relative;
  display: inline-block;
  &::after {
    position: absolute;
    bottom: -6px;
    left: 0;
    content: '';
    width: 100%;
    height: 1px;
    background: #fff;
    transform: scale(0, 1);
    transform-origin: right top;
    transition: transform .3s;
  }
  &:hover {
    &::after {
      transform-origin: left top;
      transform: scale(1, 1);
    }
  }
}



/* =================================
 bg
================================= */


/* =================================
 box
================================= */
.c_txt_box_1 {
  .subttl {
    margin-bottom:2.49vw;
    .cat {
      border-radius: 9999px;
      border: 1px solid $color_1;
      text-align: center;
      padding: 0.73vw /*10px*/ 2.93vw /*40px*/;
      display: inline-block;
    }
    .heading {            
      margin: 1.1vw /*15px*/ 0 0;
    }
  }
  .info_box {
    display: flex;
    .info_column {
      .info_column_heading {
        color: $color_3;
        margin: 0 0 0.88vw /* 12px*/;
      }
      .info_column_txt {
        &.jp {
          font-family: $font_2;
        }
      }
      & + .info_column {
        margin-left: 2.93vw /*40px*/;
      }
    }
    & + .info_box {
      margin-top: 2.93vw /*40px*/;
      & + .info_box {
        margin-top: 0.73vw /*10px*/;
      }
    }
  }
  
  @include sp {
    .subttl {
      margin-bottom: 5.33vw;
      .cat {
        padding: 2.67vw 6.4vw;
      }
      .heading {
        margin: 4vw 0 0;              
      }
    }
    .info_box {
      flex-direction: column;
      .info_column {
        .info_column_heading {
          margin: 0 0 1.33vw;                  
        }
        & + .info_column {
          margin-left: 0;
          margin-top: 5.33vw;
        }
      }
      & + .info_box {
        margin-top: 10.67vw /*40px*/;
        & + .info_box {
          margin-top: 2.67vw /*10px*/;
        }
      }      
    }
    
  }
}



/* =================================
 block
================================= */


/* =================================
 accordion
================================= */
.c_accordion_nav {
  @include sp {
    border: 3px solid $color_4;
    border-radius: 25px;
    padding: 0 23px;
    .accordion_btn {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      font-size: 16px;
      font-weight: bold;
      line-height: 24px;
      color: $color_4;
      text-align: center;
      position: relative;
      height: 44px;
      cursor: pointer;
      &::before {
        content: "";
        display: block;
        height: 5px;
        width: 5px;
        border-bottom: solid 2px #fff;
        border-right: solid 2px #fff;
        background: transparent;
        transform: rotate(-315deg);
        position: absolute;
        right: 6px;
        top: 19px;
        z-index: 1;
        transform-origin:3px 3px;
        transition: 0.3s;
      }
      &::after {
        content: "";
        position: absolute;
        display: block;
        height: 17px;
        width: 17px;
        border-radius: 9999px;
        background: $color_4;
        top: 50%;
        right: 0;
        transform: translateY(-50%);
      }
      &.selected {
        &::before {
          transform: rotate(-135deg);
        }
      }
    }
    .list {
      display: none;
      .item {
        .link {
          border-top: 1px solid $color_4;
          border-bottom: none;
          border-right: none;
          border-left: none;
          border-radius: 0;
          height: 44px;
          box-shadow:none; 
          .txt {
            padding-left: 0;
            font-size: 16px;
            &::before,
            &::after {
              content: none;
            }
          }
        }
      }
    }
  }
}

/* =================================
 pager
================================= */
.pager_wrap {
  display: flex;
  justify-content: center;
  /*margin: 9.52vw 0 0;*/
  padding-top: 9.52vw /*130px */;
  /*border-top: 1px solid $color_1;*/

  .wp-pagenavi {
    display: flex;
    justify-content: center;
    align-items: flex-end;
    a,
    .current{
      font-size: 0.95vw;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 3.07vw /*42px*/;
      border-bottom: 1px solid $color_1;
      padding-bottom: 0.95vw /*13px*/;
      min-height: 2.56vw /*35px*/;;
      & + a {
        margin-left: 1.1vw /*15px*/;
      }      
      & + .current {
        margin-left: 1.1vw /*15px*/;
      }      
    }
    .current {
      border-bottom: 1px solid $color_3;
      color: $color_3;
    }
    .nextpostslink,
    .previouspostslink{
      position: relative;
      &::before {
        content: "";
        display: block;
        width: 0.88vw /*12px*/;
        height: 0.88vw /*12px*/;
        background-image: url("../img/common/icon_arrow_sp.svg");
        background-size: cover;
      }
    }
    .previouspostslink{
      &::before {
        transform: rotate(180deg);
      }
    }
  }
  
  @include sp {
    padding-top: 9vw /*130px*/;
    .wp-pagenavi {
      a,
      .current {
        font-size: 3.47vw;
        width: 11.2vw /*42px*/;
        padding-bottom: 3.47vw /*13px*/;
        min-height: 9.33vw /*35px*/;
        & + a {
          margin-left: 4vw /*15px*/;
        }      
        & + .current {
          margin-left: 4vw /*15px*/;
        }      

      }
      .nextpostslink,
      .previouspostslink{
        &::before {
          width: 3.2vw /*12px*/;
          height: 3.2vw /*12px*/;
        }
      }        
    }



  }
  
  
  
  
}

